/**********************************************************************************************
 * COLORS.CSS
 *
 * Hartford standard colors, and other UI colors.
 **********************************************************************************************/

$black: #000;
$blue: #3a5a78;
$blue-active: #4E79A2;
$blue-light: #b6d3e9;
$linus-blanket: #eaf2f8;
$blue-hover: #68b5e4;
$blue-link: #2477bf;
$blue-light-table-hover: #deecf7;
$blue-progress: #68b5e4;
$bluegreen: #068f96;
$gray: #484848;
$gray-dark: #3d3d3d;
$gray-pewter: #6B6B6B;
$gray-placeholder: #757575;
$gray-background: #f7f7f7;
$gray-silver: #aaaaaa;
$gray-light: #dadada;
$gray-lighter: #ededed;
$gray-medium: #888;
$gray-hover: #fafafa;
$gray-tile: #6c6c6c;
$gray-body: #333;
$green: #118654;
$green-active: #13a060;
$purple: #4d4369;
$plum: #847094;
$red: #822b2f;
$red-error: #b83b2f;
$red-salmon: #c0504d;
$white: #fff;
$yellow: #efc21b;
$yellow-warning: #e87502;

 $darkBlue: $blue;
 $lightBlue: $blue-light;

 $mYellow: $yellow;
 $mMoss: $green;
 $mCream: $gray-light;
 $mGreen: $green;
 $mBlue: $blue;
 $mLightPurple: $plum;
 $mOrange: $red-salmon;
	
 $sYellow: $yellow;
 
 $sOrange: $red-salmon;
 $sSalmon: $red-salmon;
 $sPurple: $purple;
 $sBlueGreen: $bluegreen;
 $sPlum: $plum;
 $sGreen: $green;
	
/* The Hartford Bright Accent Colors */ 
 $bYellow: $yellow;
 $bCyan: $bluegreen;
 $bRed: $red;
 $ctaWebButton: $red;
 $bBrightGreen: $green;
 $bBlue: $blue-light;
 $bDarkRed: $red;
 $bGreen: $green;
 
/* The Hartford Pale Accent Colors */
 $pYellow: $yellow;

 $linkColor: $blue-link;
 $headerLinkColor: $blue-link;
 $disabledGray: $gray-light;

 $veryLightGray: $gray-background;
 $gray14: $gray-lighter;
 $lightGray: $gray-lighter;
 $gray12: $gray-light;
 $disabledGray: $gray-light;
 $gray10: $gray-silver;
 $gray9: $gray-silver;
 $gray6: $gray-pewter;
 $gray4: $gray;
 $gray3: $gray-dark;
 
 $gray2: $gray-body;
 $headerRuleColor: $gray12;
 $successBackground: #e5f0c8;
 $errorBackground: #f2dede;
 $errorBorder: #DC3942;
 $warnBackground: #fcf8e3;
 $focusButtonShadow: #4a7acc;
 $focusColor: #F0F6FB;
 $focusBorderColor: #B6D3E9;
 $errorBackgroundColor: #fbf7f7;
 $redPDF: #ff0000;
 
 $dropTargetHeaderColor: #8b859d;
 $dropTargetColor: #cac7d2;
 $dropBorderColor: #948ea5;

 $hoverColor: lighten($red, 10%);

$paginatorColor: #4f4f4f;
$activeStateColor: #1c4257;
$scrollbar-highlight-color: #698499;
$scrollbar-shadow-color: #9cadbd;
$scrollbar-face-color: #9cadbd;
$scrollbar-base-color: #010304;
$mediumBlueBackground: $blue-link;
