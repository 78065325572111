/**********************************************************************************************
 * HARTFORD.CSS
 *
 * CSS version of the Hartford standard brand colors.
 **********************************************************************************************/

@import "_colors";

/* Hartford Brand Colors */

.white {
	color: $white;
}

.lightBlue {
	color: $lightBlue;
}

.gray {
	color: $gray;
}

.darkBlue {
	color: $darkBlue;
}

.red {
	color: $red;
}

/* Hartford Muted Accent Colors */

.mYellow {
	color: $mYellow;
}

.mMoss {
	color: $mMoss;
}

.mCream {
	color: $mCream;
}

.mGreen {
	color: $mGreen;
}

.mBlue {
	color: $mBlue;
}

.mLightPurple {
	color: $mLightPurple;
}

.mOrange {
	color: $mOrange;
}

/* Hartford Standard Accent Colors */

.sYellow {
	color: $sYellow;
}

.sOrange {
	color: $sOrange;
}

.sSalmon {
	color: $sSalmon;
}

.sPurple {
	color: $sPurple;
}

.sBlueGreen {
	color: $sBlueGreen;
}

.sPlum {
	color: $sPlum;
}

.sGreen {
	color: $sGreen;
}

/* The Hartford Bright Accent Colors */

.bYellow {
	color: $bYellow;
}

.bCyan {
	color: $bCyan;
}

.bRed {
	color: $bRed;
}

.ctaWebButton {
	color: $ctaWebButton;
}

.bBrightGreen {
	color: $bBrightGreen;
}

.bBlue {
	color: $bBlue;
}

.bDarkRed {
	color: $bDarkRed;
}

.bGreen {
	color: $bGreen;
}

/* Hartford Brand Colors */

.whiteBackground {
	background-color: $white;
}

.lightBlueBackground {
	background-color: $lightBlue;
}

.grayBackground {
	background-color: $gray;
}

.darkBlueBackground {
	background-color: $darkBlue;
}

.redBackground {
	background-color: $red;
}

/* Hartford Muted Accent Colors */

.mYellowBackground {
	background-color: $mYellow;
}

.mMossBackground {
	background-color: $mMoss;
}

.mCreamBackground {
	background-color: $mCream;
}

.mGreenBackground {
	background-color: $mGreen;
}

.mBlueBackground {
	background-color: $mBlue;
}

.mLightPurpleBackground {
	background-color: $mLightPurple;
}

.mOrangeBackground {
	background-color: $mOrange;
}

/* Hartford Standard Accent Colors */

.sYellowBackground {
	background-color: $sYellow;
}

.sOrangeBackground {
	background-color: $sOrange;
}

.sSalmonBackground {
	background-color: $sSalmon;
}

.sPurpleBackground {
	background-color: $sPurple;
}

.sBlueGreenBackground {
	background-color: $sBlueGreen;
}

.sPlumBackground {
	background-color: $sPlum;
}

.sGreenBackground {
	background-color: $sGreen;
}

/* The Hartford Bright Accent Colors */

.bYellowBackground {
	background-color: $bYellow;
}

.bCyanBackground {
	background-color: $bCyan;
}

.bRedBackground {
	background-color: $bRed;
}

.ctaWebButtonBackground {
	background-color: $ctaWebButton;
}

.bBrightGreenBackground {
	background-color: $bBrightGreen;
}

.bBlueBackground {
	background-color: $bBlue;
}

.bDarkRedBackground {
	background-color: $bDarkRed;
}

.bGreenBackground {
	background-color: $bGreen;
}

.lightGrayBackground {
	background-color: $lightGray;	
}

.lightGray {
	color: $lightGray;	
}

.veryLightGray {
	color: $veryLightGray;	
}

.veryLightGrayBackground {
	background-color: $veryLightGray;	
}

.darkGrayBackground {
	background-color: $gray6;	
}

.darkGray {
	color: $gray6;	
}

.mediumBlueBackground {
	background-color: $mediumBlueBackground;	
}
